<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <p class="text-center font-weight-light text-h3">Neuen User anlegen</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Formular, um ein neues Unternehmen einzutragen
          </v-card-title>
          <v-card-subtitle v-text="resMassage" />
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="newUser.companyName"
                :rules="formRules.companyName"
                label="Name des Unternehmens"
                required
              />
              <v-text-field
                v-model="newUser.email"
                :rules="formRules.email"
                label="E-Mail-Adresse des Anspechpartners"
                required
              />
              <v-text-field
                v-model="newUser.password"
                :rules="formRules.password"
                label="Passwort"
                :append-icon="
                  formRules.showPassword ? 'mdi-eye' : 'mdi-eye-off'
                "
                :type="formRules.showPassword ? 'text' : 'password'"
                autocomplete="new-password"
                @click:append="formRules.showPassword = !formRules.showPassword"
                required
              />
              <v-text-field
                v-model="newUser.password2"
                :rules="formRules.password"
                label="Passwort wiederholen"
                :append-icon="
                  formRules.showPassword ? 'mdi-eye' : 'mdi-eye-off'
                "
                :type="formRules.showPassword ? 'text' : 'password'"
                autocomplete="new-password"
                @click:append="formRules.showPassword = !formRules.showPassword"
                required
              />
              <v-select
                v-model="newUser.region"
                :items="options.region"
                :rules="[(v) => !!v || 'Eingabe erforderlich']"
                label="Bitte Region auswählen"
                required
              />
              <v-select
                v-model="newUser.federalState"
                :items="options.federalState"
                :rules="[(v) => !!v || 'Eingabe erforderlich']"
                label="Bitte Bundesland auswählen"
                required
              />
              <v-switch
                v-model="newUser.emailNotification"
                label="E-Mail Benachrichtigung"
              ></v-switch>
            </v-form>
            <v-btn @click="sendNewUserToAPI" color="primary" class="mt-5" block>
              Unternehmen Erstellen
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="newUserToCopy.length > 0">
      <v-col>
        <v-card>
          <v-card-text>
            <v-textarea
              v-model="newUserToCopy"
              label="Informationen des neuen Users zum kopieren."
              counter
            ></v-textarea>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "createNewUser",
  data() {
    return {
      resMassage: "",
      newUser: {
        companyName: "",
        email: "",
        password: "",
        password2: "",
        region: "",
        federalState: "",
        emailNotification: true,
      },
      newUserToCopy: "",
      options: {
        region: ["South", "North"],
        federalState: [
          "BW",
          "BY",
          "BE",
          "BB",
          "HB",
          "HH",
          "HE",
          "MV",
          "NI",
          "NW",
          "RP",
          "SL",
          "SN",
          "ST",
          "SH",
          "TH",
        ],
      },
      valid: true,
      formRules: {
        showPassword: false,
        companyName: [
          (v) => !!v || "Unternehmensname ist erforderlich",
          (v) => (v && v.length > 6) || "Sollte mehr als 6 Buchstaben haben",
        ],
        email: [
          (v) => !!v || "E-Mail-Adresse ist erforderlich",
          (v) => /.+@.+\..+/.test(v) || "E-Mail-Adresse ist nicht valide",
        ],
        password: [
          (v) => !!v || "Passwort ist erforderlich",
          (v) => (v && v.length >= 8) || "Sollte mehr als 8 Buchstaben haben",
        ],
      },
    };
  },
  methods: {
    sendNewUserToAPI: async function () {
      if (!this.$refs.form.validate()) {
        this.resMassage = "Angaben nicht valide...";
        return;
      }
      try {
        const res = await axios.post(
          this.$store.state.apiUrl + "/api/users/register",
          this.newUser
        );
        this.resMassage = JSON.stringify(res.data);
        if (res.status === 200) {
          for (const [key, value] of Object.entries(this.newUser)) {
            if (key === "password2") {
              continue;
            }
            this.newUserToCopy += key + ": " + value + "\n";
          }
          this.newUserToCopy += "\n\n";

          this.newUser = {
            companyName: "",
            email: "",
            password: "",
            password2: "",
            region: "",
            federalState: "",
          };
          this.resetValidation();
        }
      } catch (error) {
        this.resMassage = JSON.stringify(error.response.data);
        return;
      }
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style></style>
